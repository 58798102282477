<template>
  <div class="voyager-home">
    <IndexHeader />
    <div class="voyager-home-content">
      <div class="menu-fields">
        <div class="menu-fields-header">
          {{ voyagerTitle }}
          <div class="item-code-btn">
            <el-button type="" size="mini" @click="handleTitle">切换数据库</el-button>
          </div>
        </div>
        <div class="menu-fields-content">
          <div class="menu-fields-content-item item-space">
            <div class="item-code-title">{{ moduleTitle ? moduleTitle : '请选择模块' }}</div>
            <div class="item-code-btn">
              <el-button type="" size="mini" @click="handleVayagerCode">选择</el-button>
            </div>
          </div>
          <div class="menu-fields-content-item item-space">
            <div class="item-code-title">{{ fieldTitle ? fieldTitle : '' }}</div>
            <div class="item-code-btn">
              <el-button type="" size="mini" @click="handleVayagerField">选择</el-button>
            </div>
          </div>
          <div class="menu-fields-content-item item-space" v-show="fieldChildTitle">
            <div class="item-code-title">{{ fieldChildTitle ? fieldChildTitle : '' }}</div>
            <div class="item-code-btn">
              <el-button type="" size="mini" @click="handleVayagerFieldChild">选择</el-button>
            </div>
          </div>
          <div class="" v-loading="loading" v-show="fieldChildList.length !== 0">
            <div class="field-header">Fields</div>
            <draggable
              v-model="fieldChildList"
              :sort="false"
              :group="{
                name: 'dragFieldList',
                pull: 'clone',
                put: false
              }"
              animation="300"
            >
              <div class="field-item" v-for="item in fieldChildList" :key="item.id">
                <div class="field-item-label">
                  <div class="field-item-label-select"></div>
                  <div class="field-item-label-type">
                    <img v-if="item.data.datatype === 'date'" :src="calendarImg" alt="" />
                    <img
                      class="img-big"
                      v-if="item.data.datatype === 'number'"
                      :src="numberImg"
                      alt=""
                    />
                    <img
                      class="img-big"
                      v-if="item.data.datatype === 'string'"
                      :src="stringImg"
                      alt=""
                    />
                  </div>
                  <span>{{ item.title }}</span>
                </div>
                <div class="field-item-btn">
                  <img :src="filterImg" alt="" />
                  <img :src="addImg" alt="" />
                </div>
              </div>
            </draggable>
          </div>
          <div class="">
            <div class="field-header">Wildcards</div>
            <draggable
              v-model="fieldWildList"
              :sort="false"
              :group="{
                name: 'dragFieldList',
                pull: 'clone',
                put: false
              }"
              animation="300"
            >
              <div class="field-item" v-for="item in fieldWildList" :key="item.id">
                <div class="field-item-label">
                  <div class="field-item-label-select"></div>
                  <div class="field-item-label-type">
                    <img v-if="item.data.datatype === 'date'" :src="calendarImg" alt="" />
                    <img
                      class="img-big"
                      v-if="item.data.datatype === 'number'"
                      :src="numberImg"
                      alt=""
                    />
                    <img
                      class="img-big"
                      v-if="item.data.datatype === 'string'"
                      :src="stringImg"
                      alt=""
                    />
                  </div>
                  <span>{{ item.title }}</span>
                </div>
                <div class="field-item-btn">
                  <img :src="addImg" alt="" />
                </div>
              </div>
            </draggable>
          </div>
        </div>
      </div>
      <div class="menu-filter">
        <div class="menu-filter-header">
          <div class="filter-header">Chart Type</div>
          <div class="clear-btn">
            <el-button type="" size="mini" @click="">清除</el-button>
          </div>
        </div>
        <div class="menu-filter-content">
          <div class="">
            <div class="filter-header">Query</div>
            <div class="filter-item" v-for="item in queryList" :key="item.id">
              <div class="filter-item-label">{{ item.title }}</div>
              <div class="filter-item-content">
                <draggable
                  handle=".mover"
                  v-model="item.content"
                  group="dragFieldList"
                  animation="300"
                  @add="QueryAdd"
                >
                  <transition-group style="min-height: 12px; padding-bottom: 20px; display: block">
                    <div v-for="ele in item.content" :key="ele.id">
                      <div class="field-item-label">
                        <div class="field-item-label-select"></div>
                        <span>{{ ele.title }}</span>
                        <div class="field-item-label-delete">
                          <i class="el-icon-close"></i>
                        </div>
                      </div>
                    </div>
                  </transition-group>
                </draggable>
              </div>
            </div>
          </div>
          <div class="">
            <div class="filter-header">Metrics</div>
            <draggable
              v-model="fieldCountList"
              :group="{
                name: 'dragFieldList',
                pull: 'clone',
                put: false
              }"
              animation="300"
            >
              <div class="filter-item" v-for="item in fieldCountList" :key="item.id">
                <div class="field-item-label">
                  <div class="field-item-label-select"></div>
                  <div class="field-item-label-type">
                    <img class="img-big" :src="numberImg" alt="" />
                  </div>
                  <span>{{ item.title }}</span>
                </div>
              </div>
            </draggable>
          </div>
          <div class="">
            <div class="filter-header">Filter</div>
            <draggable v-model="filterList" @add="FilterAdd" group="dragFieldList" animation="300">
              <transition-group style="min-height: 12px; padding-bottom: 20px; display: block">
                <div v-for="item in filterList" :key="item.id">
                  <div>{{ item.data.datatype }}</div>
                  <div v-if="item.data.datatype === 'date'"></div>
                </div>
              </transition-group>
            </draggable>
          </div>
        </div>
      </div>
      <div class="content-view">
        <div class=""></div>
      </div>
    </div>
    <VayagerCodeDialog
      ref="vayagerCodeDialog"
      :moduleList="moduleList"
      @save="_dialogSave"
    ></VayagerCodeDialog>
    <VayagerFieldDialog
      ref="vayagerFieldDialog"
      :fieldList="Retrieval_field_grouping"
      @save="_dialogFieldSave"
    ></VayagerFieldDialog>
    <VayagerFieldChildDialog
      ref="vayagerFieldChildDialog"
      :tree_list="tree_list"
      @save="_dialogFieldChildSave"
    ></VayagerFieldChildDialog>
  </div>
</template>

<script>
import draggable from 'vuedraggable'

import IndexHeader from '@/components/IndexHeader.vue'
import VayagerCodeDialog from './components/VayagerCodeDialog'
import VayagerFieldDialog from './components/VayagerFieldDialog'
import VayagerFieldChildDialog from './components/VayagerFieldChildDialog'

export default {
  name: 'Voyager',
  components: {
    IndexHeader,
    draggable,
    VayagerCodeDialog,
    VayagerFieldDialog,
    VayagerFieldChildDialog
  },
  data() {
    return {
      loading: false,
      addImg: require('@/assets/img/voyager/add.png'),
      calendarImg: require('@/assets/img/voyager/calendar.png'),
      numberImg: require('@/assets/img/voyager/number.png'),
      stringImg: require('@/assets/img/voyager/string.png'),
      filterImg: require('@/assets/img/voyager/filter.png'),

      voyagerTitle: '专病数据库',

      queryList: [
        {
          id: 'xData',
          title: 'x',
          content: []
        },
        {
          id: 'yData',
          title: 'y',
          content: []
        }
      ],
      fieldCode: '',
      fieldChildCode: '',
      fieldChildList: [],
      fieldCountList: [
        {
          id: 'count',
          title: 'Count'
        }
      ],
      fieldWildList: [
        {
          id: 'Categorical',
          title: '关键字分类',
          data: {
            datatype: 'string'
          }
        },
        {
          id: 'Year',
          title: '按年份分类',
          data: {
            datatype: 'date'
          }
        },
        {
          id: 'Quantitative',
          title: '按数字类型分类',
          data: {
            datatype: 'number'
          }
        }
      ],
      Retrieval_field_grouping: [],
      tree_list: [],
      moduleCode: 'AorticInfo',
      moduleTitle: '主瓣',
      fieldTitle: '',
      fieldChildTitle: '',
      moduleList: [],
      filterList: []
    }
  },
  created() {
    this.getModuleList()
  },
  mounted() {
    this.setScrollBar()
    // 浏览器缩放，更新ScrollBar位置
    window.addEventListener('resize', this.setScrollBar)
  },
  watch: {},
  methods: {
    // count 函数
    // 支持的函数：MIN/MAX/COUNT/SUM/AVG/MEDIAN/BIN
    // 数据类型：string/number/date

    // 拖拽相关
    QueryAdd() {
      console.log(111)
      console.log(this.queryList)
      this.queryList.forEach((element) => {
        if (element.content.length > 1) {
          let arr = []
          arr.push(element.content[0])
          element.content = arr
        }
      })
    },
    FilterAdd() {
      console.log(222)
      console.log(this.filterList)
    },

    handleTitle() {
      if (this.voyagerTitle === '专病数据库') {
        this.voyagerTitle = '队列研究'
      } else {
        this.voyagerTitle = '专病数据库'
      }
    },

    //
    _dialogSave(code, title) {
      this.moduleCode = code
      this.moduleTitle = title
      this.getFieldGrouping()
    },
    _dialogFieldSave(code, title) {
      this.fieldCode = code
      this.fieldTitle = title
      this.changeNodeOptions()
    },
    _dialogFieldChildSave(code, title) {
      this.fieldChildCode = code
      this.fieldChildTitle = title
      this.tree_list.forEach((element) => {
        if (element.id === this.fieldChildCode) {
          this.fieldChildList = element.child
        }
      })
    },
    handleVayagerCode() {
      this.$refs.vayagerCodeDialog.Show(this.moduleCode)
    },
    handleVayagerField() {
      this.$refs.vayagerFieldDialog.Show(this.fieldCode)
    },
    handleVayagerFieldChild() {
      this.$refs.vayagerFieldChildDialog.Show(this.fieldChildCode)
    },
    getModuleList() {
      this.$api.get(`/v1/webconsole/study/mymodulelist?moduleType=1`).then((res) => {
        if (res.data && res.data.data.length > 0) {
          this.moduleList = res.data.data
          this.getFieldGrouping()
        }
      })
    },
    getFieldGrouping() {
      this.$api.get(`/v1/webconsole/search/root/${this.moduleCode}`).then((res) => {
        if (res.data && res.data.data.length > 0) {
          this.Retrieval_field_grouping = res.data.data
          this.fieldCode = this.Retrieval_field_grouping[0].id
          this.fieldTitle = this.Retrieval_field_grouping[0].title
          this.fieldChildList = []
          this.changeNodeOptions()
        }
      })
    },
    changeNodeOptions() {
      this.loading = true
      this.$api
        .get(`/v1/webconsole/search/tree/${this.moduleCode}/${this.fieldCode}`)
        .then((res) => {
          this.get_tree_data(res.data.data)
        })
    },
    // 获得所选分组的所有树节点
    get_tree_data(val) {
      this.tree_list = this.turnToTreeOfOneRootPlus(val.child)
      if (this.tree_list[0].child) {
        this.fieldChildCode = this.tree_list[0].id
        this.fieldChildTitle = this.tree_list[0].title
        this.fieldChildList = this.tree_list[0].child
      } else {
        this.fieldChildTitle = ''
        this.fieldChildList = this.tree_list
      }
      this.loading = false
    },
    // 建树
    turnToTreeOfOneRootPlus(arr) {
      if (arr) {
        for (let obj of arr) {
          obj['title'] = obj['data'].title
          if (obj['child']) {
            this.turnToTreeOfOneRootPlus(obj['child'])
          }
        }
        return arr
      }
    },
    setScrollBar() {
      var div = document.querySelector('.voyager-home-content')
      div.style.height = window.innerHeight - 60 + 'px'
      div.style.width = window.innerWidth + 'px'
    }
  }
}
</script>

<style scoped lang="less">
.voyager-home-content::-webkit-scrollbar {
  width: 12px;
}
.voyager-home-content::-webkit-scrollbar-track {
  border-radius: 10px;
}
.voyager-home-content::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.1);
}
.voyager-home-content::-webkit-scrollbar-thumb:window-inactive {
  background: #ccc;
}

.voyager-home {
  .voyager-home-content {
    display: flex;
    overflow-y: auto;
    // scrollbar-width: none;
    // -ms-overflow-style: none;
    background-color: #f5f8fa;
    .menu-fields::-webkit-scrollbar {
      display: none;
    }
    .menu-fields {
      flex: 0 1 260px;
      background-color: #38425d;
      overflow: auto;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
      .menu-fields-header {
        padding-left: 10px;
        height: 52px;
        color: #fff;
        font-weight: bold;
        font-size: 22px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .item-code-btn {
          margin-right: 10px;
        }
      }
      .menu-fields-content {
        padding-left: 10px;
        .field-header {
          font-size: 20px;
          font-weight: bold;
          color: #fff;
        }
        .field-item {
          height: 22px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          position: relative;
          background: #c9cbd1;
          margin-bottom: 5px;
          margin-right: 5px;
          border-radius: 5px;
          padding: 2px 0;
          .field-item-label {
            display: flex;
            align-items: center;
            .field-item-label-select {
              width: 16px;
            }
            .field-item-label-type {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 22px;
              img {
                width: 15px;
                height: 15px;
                cursor: pointer;
              }
              .img-big {
                width: 20px;
                height: 20px;
              }
            }
            span {
              cursor: move;
            }
          }
          .field-item-btn {
            display: flex;
            align-items: center;
            position: absolute;
            right: 4px;
            img {
              width: 14px;
              height: 14px;
            }
            img:hover {
              border-radius: 4px;
              background: #9e9e9e;
              color: #fff;
              cursor: pointer;
            }
          }
        }
        .menu-fields-content-item {
          margin-bottom: 8px;
          display: flex;
          align-items: center;
          .item-code-title {
            color: #fff;
          }
          .item-code-btn {
            margin-right: 10px;
          }
        }
        .item-space {
          justify-content: space-between;
        }
      }
    }
    .menu-filter {
      flex: 0 1 260px;
      background-color: #e2e9f3;
      overflow: auto;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
      .menu-filter-header {
        height: 32px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .clear-btn {
          margin: 0 8px;
        }
        .filter-header {
          font-size: 20px;
          font-weight: bold;
          padding: 0 10px;
        }
      }

      .menu-filter-content {
        padding-left: 10px;
        .filter-header {
          font-size: 20px;
          font-weight: bold;
        }
        .field-item-label {
          display: flex;
          align-items: center;
          .field-item-label-select {
            width: 16px;
          }
          .field-item-label-type {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 22px;
            img {
              width: 15px;
              height: 15px;
              cursor: pointer;
            }
            .img-big {
              width: 20px;
              height: 20px;
            }
          }
          span {
            cursor: move;
          }
        }
        .filter-item {
          background: #c0c7d4;
          height: 32px;
          margin-bottom: 5px;
          margin-right: 5px;
          border-radius: 5px;
          padding-left: 5px;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        .filter-item-content {
          height: 30px;
          line-height: 30px;
          width: 180px;
          background: #fff;
          margin-right: 2px;
          border-radius: 4px;
          .field-item-label {
            display: flex;
            align-items: center;
            .field-item-label-select {
              width: 16px;
            }
            .field-item-label-type {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 22px;
              img {
                width: 15px;
                height: 15px;
                cursor: pointer;
              }
              .img-big {
                width: 20px;
                height: 20px;
              }
            }
            span {
              cursor: move;
            }
          }
        }
      }
    }
  }
}
</style>
