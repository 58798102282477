<style scoped lang="less">
.button-panel {
  width: 100%;
  bottom: 0;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-around;
}
.top-item-row {
  margin: 10px 15px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.item-code {
  min-width: 200px;
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.item-code:hover {
  background: #d9dadc;
  cursor: pointer;
}
.item-select {
  background: #d9dadc;
}
.code-title {
  height: 52px;
  line-height: 52px;
  padding: 0 20px;
  font-weight: bold;
}
</style>

<template>
  <CmDialogBorder size="mini" :zIndex="zIndex" title="添加模块" v-show="isShow" @close="Close">
    <div class="code-title">请选择一个模块进行数据分析</div>
    <div class="top-item-row">
      <div
        class="item-code"
        :class="item.moduleCode === moduleCode ? 'item-select' : ''"
        v-for="item in moduleList"
        :key="item.id"
        @click="handleCode(item)"
      >
        <div>
          <i class="el-icon-s-data"></i>
          {{ item.moduleTitle }}
        </div>
        <div>{{ item.moduleCode }}</div>
      </div>
    </div>

    <!-- <div class="button-panel">
      <el-button
        type="success"
        class="common-button commonBtn"
        icon="el-icon-finished"
        v-debounce="Save"
      >
        保存
      </el-button>
      <el-button
        type="danger"
        class="common-button commonBtn"
        icon="el-icon-close"
        v-debounce="Close"
      >
        关闭
      </el-button>
    </div> -->
  </CmDialogBorder>
</template>

<script>
import CmDialogBorder from '@/components/CmDialogBorder.vue'
import { PopupManager } from 'element-ui/lib/utils/popup'
// import deepClone from '@/plugins/clone'

export default {
  name: 'VayagerCodeDialog',
  components: {
    CmDialogBorder
  },
  data() {
    return {
      zIndex: PopupManager.nextZIndex(),
      isShow: false,
      moduleCode: '',
      moduleTitle: ''
    }
  },
  props: {
    moduleList: {
      type: Array,
      default: () => []
    }
  },
  watch: {},
  created() {},
  methods: {
    handleCode(val) {
      this.moduleCode = val.moduleCode
      this.moduleTitle = val.moduleTitle
      this.$emit('save', this.moduleCode, this.moduleTitle)
      this.isShow = false
    },
    Show(bloodVessel) {
      if (bloodVessel) {
        this.moduleCode = bloodVessel
      }

      this.isShow = true
    },

    Close() {
      this.isShow = false
    }
  }
}
</script>
