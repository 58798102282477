<style scoped lang="less">
.button-panel {
  width: 100%;
  bottom: 0;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-around;
}
.top-item-row {
  margin: 10px 15px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.item-code {
  min-width: 200px;
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.item-code:hover {
  background: #d9dadc;
  cursor: pointer;
}
.item-select {
  background: #d9dadc;
}
.code-title {
  height: 52px;
  line-height: 52px;
  padding: 0 20px;
  font-weight: bold;
}
</style>

<template>
  <CmDialogBorder size="mini" :zIndex="zIndex" title="选择字段" v-show="isShow" @close="Close">
    <div class="top-item-row">
      <span class="code-title">请选择字段</span>

      <el-select style="width: 70%" v-model="fieldChildCode" clearable @change="handleCode">
        <el-option
          v-for="item in tree_list"
          :key="item.id"
          :label="item.title"
          :value="item.id"
        ></el-option>
      </el-select>
    </div>
  </CmDialogBorder>
</template>

<script>
import CmDialogBorder from '@/components/CmDialogBorder.vue'
import { PopupManager } from 'element-ui/lib/utils/popup'
// import deepClone from '@/plugins/clone'

export default {
  name: 'VayagerFieldDialog',
  components: {
    CmDialogBorder
  },
  data() {
    return {
      zIndex: PopupManager.nextZIndex(),
      isShow: false,
      fieldChildCode: '',
      fieldChildTitle: ''
    }
  },
  props: {
    tree_list: {
      type: Array,
      default: () => []
    }
  },
  watch: {},
  created() {},
  methods: {
    handleCode(val) {
      this.fieldChildCode = val
      this.tree_list.forEach((element) => {
        if (element.id === this.fieldChildCode) {
          this.fieldChildTitle = element.title
        }
      })
      this.$emit('save', this.fieldChildCode, this.fieldChildTitle)
      this.isShow = false
    },
    Show(bloodVessel) {
      if (bloodVessel) {
        this.fieldChildCode = bloodVessel
      }

      this.isShow = true
    },

    Close() {
      this.isShow = false
    }
  }
}
</script>
